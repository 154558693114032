import React, { Suspense, lazy } from 'react';
import "./App.css";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import BackgroundVid from './assets/Loader.mp4';
const Home = lazy(() => import('./pages/Home'));
const Enggsolutions = lazy(() => import('./pages/Enggsolutions'));
const Training = lazy(() => import('./pages/Training'));
const Work = lazy(() => import('./pages/Work'));
const About = lazy(() => import('./pages/About'));
const Team = lazy(() => import('./pages/Team'));
const Contact = lazy(() => import('./pages/Contact'));
const IdeaLab = lazy(() => import('./pages/IdeaLab'));
const Footer = lazy(() => import('./components/footer/Footer'));
const ScrollToTop = lazy(() => import('./components/scrollToTop/ScrollToTop'));
const ScrollTopRoute = lazy(() => import('./components/scrollToTop/ScrollTopRoute'));

const myVideo = {
  'width': '100vw',
  'height': '100vh',
  'object-fit': 'cover',
  'top': 0,
  'left': 0
}


function App() {
  return (
    <Suspense fallback={
      <div className='loading'>
      </div>
    }>
      <BrowserRouter>
        <div className="App">
          <ScrollToTop />
          <ScrollTopRoute>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/enggsolutions" component={Enggsolutions} />
              <Route exact path="/training" component={Training} />
              <Route exact path="/work" component={Work} />
              <Route exact path="/about" component={About} />
              <Route exact path="/team" component={Team} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/idealab" component={IdeaLab} />
              <Route exact path="/loader" >
                <div>
                  <div className='loading'>
                  </div>
                </div>
              </Route>
            </Switch>
          </ScrollTopRoute>
        </div>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
